<template>
  <div>
    <div class="top-bar">
      <div class="logo">
        <!-- <a title="Admin" class="display-4">Wohlig</a> -->
        <img src="@/assets/images/logo.png" alt="logo" class="logo-img" />
        <div
          class="menu-options"
          @click="hamburgerChange"
          :class="{ active: $store.state.hamburger }"
        >
          <span class="menu-action">
            <i></i>
          </span>
        </div>
      </div>
      <div class="quick-links">
        <ul>
          <li>
            <router-link to="/#">
              <button title="Logout" class="btn btn-danger btn-small" v-on:click="logout()">
                <font-awesome-icon :icon="['fas', 'power-off']" class="fa-1x" />
              </button>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- Top Bar -->
    <!-- {{$store.state.sideNav}} -->
    <header
      class="side-header light-skin opened-menu"
      :class="{ 'nav-width': $store.state.hamburger }"
    >
      <div class="admin-details">
        <!-- <h3 style="text-transform: capitalize;">Demo</h3> -->
        <i></i>
      </div>
      <div class="menu-scroll">
        <div class="side-menus">
          <!-- <span>MAIN LINKS</span> -->
          <!-- <nav ng-include="template.menu"></nav> -->
          <nav>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/viewDashboardList">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />DASHBOARD
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/viewUserList">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />USER
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/viewHotelList">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />HOTEL
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/viewBlogList">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />BLOG
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/viewTestimonial">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />TESTIMONIALS
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/viewDestination">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />DESTINATION EXPERT
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/viewExpertTalks">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />EXPERT TALKS
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/viewInquiryList">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />INQUIRY
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/report">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />TRANSACTIONS /
                  ACCOUNTS
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/wedCalculator">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />WED CALCULATORS
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/deal-maker">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />Deal Maker &amp;
                  Hire an Expert
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/deals-offers">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />Deals &amp; Offers
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/newsletter">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />Newsletter
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/shortlist">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" />Shorlisted Hotels
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/hotelInquiry">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" /> Inquire for Hotel
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/offerDealInquiry">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" /> Inquire for Deals
                  &amp; Offer(Hotels)
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/viewUserLead">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" /> User Leads
                </div>
              </router-link>
            </div>
            <div class="icon-active-color border-bottom border-top">
              <router-link to="/viewMetaDataList">
                <div class="link-text p-3">
                  <font-awesome-icon :icon="['far', 'user']" class="fa-1x mr-2" /> Meta Data
                </div>
              </router-link>
            </div>
          </nav>
        </div>
      </div>
      <!-- Menu Scroll -->
    </header>
  </div>
</template>
<script>
import Vue from "vue";
import { Vuex, mapMutations } from "vuex";
import apiservice from "@/service/apiservice";
// import global from '@/service/global';

export default {
  data() {
    return {
      isActive: false
    };
  },
  // This is use for menu
  methods: {
    ...mapMutations({
      hamburgerChange: "changeHamburger" // map `this.increment()` to `this.$store.commit('increment')`
    }),

    logout() {
      apiservice.logout(data => {
        if (data.code === 200) {
          // this.log = data.data;

          // window.open(data.response.data);
          this.$router.push("/");
          // this.totalCount = data.data.count;
        } else {
        }
      });
    }
  }
};
</script>
<style lang="scss">
.logo {
  padding: 20px 20px;
  .logo-img {
    width: 50%;
    height: 50%;
  }
}
.menu-scroll {
  height: 100%;
}
</style>
