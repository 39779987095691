/* eslint-disable no-unused-vars */
import axios from "axios";
import _ from "vue-lodash";
import global from "@/service/global";
import moment from "moment";

export default {
  // logout
  logout: callback => {
    axios
      .get(`${global.adminurl}User/logout`)
      .then(response => {
        callback({ code: 200, response });
      })
      .catch(err => {
        callback(500, err.response);
      });
  },
  viewUserList: (data, callback) => {
    axios
      .post(`${global.adminurl}User/pagination/`, data)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  viewNewsletterList: (data, callback) => {
    axios
      .post(`${global.adminurl}Newsletter/pagination/`, data)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  getAllEnquiriesTypeforInquiries: (data, callback) => {
    axios
      .post(`${global.adminurl}Enquiries/getAllEnquiriesTypeforAdmin/`, data)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  getAllEnquiriesType: (data, callback) => {
    axios
      .post(`${global.adminurl}Enquiries/getAllEnquiriesTypeInquiriesforAdmin/`, data)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  viewInquiryList: (data, callback) => {
    axios
      .post(`${global.adminurl}Inquiry/pagination/`, data)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  viewHotelList: (data, callback) => {
    axios
      .post(`${global.adminurl}Hotel/pagination/`, data)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  viewTransactionList: (data, callback) => {
    axios
      .post(`${global.adminurl}Transaction/getTransactionByHotelId/`, data)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  // get One Inquiry
  getOneInquiry: (Id, callback) => {
    axios
      .get(`${global.adminurl}Inquiry/getOne/${Id}`)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },

  // get one hotel
  getOneHotel(Id, callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}Hotel/getOneForAdmin/${Id}`
    };
    axios(obj)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  // get One Hotel Faq
  getOneHotelFaq(data, callback) {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Hotel/getOneHotelFaq`,
      data
    };
    axios(obj)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },

  //get user profile
  getUserProfile(data, callback) {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}user/getUserByIdForAdmin`,
      data: { user: data.id }
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  getOneUserEnquiriesByIdForAdmin(data, callback) {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Enquiries/getOneUserEnquiriesByIdForAdmin`,
      data: { enquiries: data.id }
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  updateUserProfile(data, callback) {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}user/updateUserProfileByAdmin`,
      data: { user: data.user }
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // update Hotel
  updateHotel(dataToUpdate, Id, callback) {
    let obj = {};
    obj = {
      method: "put",
      url: `${global.adminurl}Hotel/updateForAdmin/${Id}`,
      data: dataToUpdate
    };
    axios(obj)
      .then(responseData => {
        callback(responseData);
      })
      .catch(err => {
        callback(err);
      });
  },
  updateExclusive: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}hotel/updateExclusive`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  getHotelMenuDetail: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}hotel/getHotelMenuDetail`,
      data: { id: data }
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  getAllHotelName: callback => {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}hotel/getAllHotelName/getName`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  awscoverImageUpload: (data, callback) => {
    axios
      .post(`${global.imageUrl}api/upload/`, data, {
        headers: { "Content-type": "multipart/form-data" }
      })
      .then(response => {
        if (response.status === 200) {
          callback(response);
        }
      })
      .catch(e => {
        callback(e);
      });
  },
  saveBlogs: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Blogs/`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  viewBlogs: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Blogs/search`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  checkBlogPosition: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Blogs/checkBlogPosition`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  getOneBlog: (data, callback) => {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}Blogs/getOneBlogForAdmin/${data}`
    };
    axios(obj)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  updateBlogs(dataToUpdate, Id, callback) {
    let obj = {};
    obj = {
      method: "put",
      url: `${global.adminurl}Blogs/update/${Id}`,
      data: dataToUpdate
    };
    axios(obj)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  deleteBlogs(Id, callback) {
    let obj = {};
    obj = {
      method: "delete",
      url: `${global.adminurl}Blogs/delete/${Id}`
    };
    axios(obj)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  // save testimonial
  saveTestimonials: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Testimonials/saveTestimonials`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // aws testimonial image upload
  awsTestimonialImageUpload: (data, callback) => {
    axios
      .post(`${global.imageUrl}api/upload/`, data, {
        headers: { "Content-type": "multipart/form-data" }
      })
      .then(response => {
        if (response.status === 200) {
          callback(response);
        }
      })
      .catch(e => {
        callback(e);
      });
  },
  // view testimonial list
  viewTestimonials: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Testimonials/pagination`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // get One Testimonial
  getOneTestimonial: (data, callback) => {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}Testimonials/getOne/${data}`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // update testimonial
  updateTestimonial(dataToUpdate, Id, callback) {
    let obj = {};
    obj = {
      method: "put",
      url: `${global.adminurl}Testimonials/update/${Id}`,
      data: dataToUpdate
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // delete Testimonial
  deleteTestimonial(Id, callback) {
    let obj = {};
    obj = {
      method: "delete",
      url: `${global.adminurl}Testimonials/delete/${Id}`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // view Reports

  viewReports: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Transaction/getAllReportForAdmin`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get hotel type
  getHotelType(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}hotelType`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // update hotel Admin
  updateHotelAdmin(dataToUpdate, Id, callback) {
    let obj = {};
    obj = {
      method: "put",
      url: `${global.adminurl}Hotel/updateHotelAdmin/${Id}`,
      data: dataToUpdate
    };
    axios(obj)
      .then(responseData => {
        callback(responseData);
      })
      .catch(err => {
        callback(err);
      });
  },
  //get all RoomAmenities
  getRoomAmenities(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}RoomAmenities/getAll/RoomAmenities`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  getBathRoomAmenities(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}BathroomAmenities/getAll/BathroomAmenities`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  getVenueType(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}VenueType/getAll/venueType`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  awsPhotoUpload(data, callback) {
    axios({
      method: "post",
      url: `${global.imageUrl}api/upload`,

      headers: {
        "content-type": "multipart/form-data; boundary=---011000010111000001101001"
      },
      data: data
    })
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  //get General
  getGeneral(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}General/getAll/general`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get HealthNWellnessFacilities
  getHealthNWellnessFacilities(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}HealthNWellnessFacilities/getAll/HealthNWellnessFacilities`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  //get SafetyNSecurity
  getSafetyNSecurity(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}SafetyNSecurity/getAll/SafetyNSecurity`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get FrontDeskServices
  getFrontDeskServices(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}FrontDeskServices/getAll/frontDeskServices`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // get Transportation
  getTransportation(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}Transportation/getAll/transportation`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // get CleaningServices
  getCleaningServices(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}CleaningServices/getAll/cleaningServices`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // get Wifi
  getWifi(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}Wifi/getAll/wiFi`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // get Activities
  getActivities(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}Activities/getAll/activities`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // get all Outdoors
  getOutdoors(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}Outdoors/getAll/outdoors`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  getInclusions(data, callback) {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Inclusions/getAll`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get one package
  getOnePackages(packageid, callback) {
    let obj = {};
    obj = {
      method: "post",
      headers: {},
      url: `${global.adminurl}Packages/getOne`,
      data: packageid
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  //get one hotel with package
  getOneHotelWithPackage(callback) {
    axios({
      method: "get",
      url: `${global.adminurl}Hotel/getOneHotelWithPackage/getPackage/${global.getHotel()}`
    })
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  awscoverImageUpload: (data, callback) => {
    axios
      .post(`${global.imageUrl}api/upload/`, data, {
        headers: { "Content-type": "multipart/form-data" }
      })
      .then(response => {
        if (response.status === 200) {
          callback(response);
        }
      })
      .catch(e => {
        callback(e);
      });
  },
  //for upload file to aws S3 bucket
  awsWeddingImageUpload: (data, callback) => {
    axios
      .post(`${global.imageUrl}api/upload/`, data, {
        headers: { "Content-type": "multipart/form-data" }
      })
      .then(response => {
        if (response.status === 200) {
          callback(response);
        }
      })
      .catch(e => {
        callback(e);
      });
  },
  // upload real wedding images
  awsRealWeddingImageUpload: (data, callback) => {
    axios
      .post(`${global.imageUrl}api/upload/`, data, {
        headers: { "Content-type": "multipart/form-data" }
      })
      .then(response => {
        if (response.status === 200) {
          callback(response);
        }
      })
      .catch(e => {
        callback(e);
      });
  },

  updateItemAmount(dataToUpdate, callback) {
    axios({
      method: "put",
      url: `${global.adminurl}Hotel/updateItemAmount/${global.getHotel()}`,
      data: dataToUpdate
    })
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  updateRemark(dataToUpdate, callback) {
    axios({
      method: "put",
      url: `${global.adminurl}Hotel/updateRemark/${global.getHotel()}`,
      data: dataToUpdate
    })
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  updatePackageAmount(dataToUpdate, callback) {
    axios({
      method: "put",
      url: `${global.adminurl}Hotel/updatePackageAmount/${global.getHotel()}`,
      data: dataToUpdate
    })
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  downloadExcel(data, fileName) {
    var path = fileName + "_" + moment().format("MMM-DD-YYYY-hh-mm-ss-a") + ".xlsx";
    var blob = new Blob([data.data]);
    var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objectUrl;
    link.setAttribute("download", path);
    document.body.appendChild(link);
    link.click();
  },
  generateExcelForAllOffersNDeals: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}OffersNDeals/generateExcelForAllOffersNDeals`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  generateExcelForAllInquiry: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Inquiry/generateExcelForAllInquiry`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  generateExcelForNewsletter: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Newsletter/generateExcelForNewsletter`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  generateExcelForEnquiries: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Enquiries/generateExcelForHotelEnquiries`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  generateExcelForAllHireExpert: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}HireExpert/generateExcelForAllHireExpert`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  generateExcelForAllReport: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Transaction/generateExcelForAllReport`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  generateExcelForAllwedCal: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}WedCalculator/generateExcelForAllwedCal`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  generateExcelForUser: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}User/generateExcelForUser`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  generateExcelForHotel: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Hotel/generateExcelForHotel`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  //get all wedCalulators list
  getAllWedCalculatedPdfList: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}WedCalculator/pagination`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  viewHireExpertNDeals: (data, callback) => {
    axios
      .post(`${global.adminurl}HireExpert/pagination/`, data)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  // get One HireExpert and deals
  getOneHireExpertNDeals: (Id, callback) => {
    axios
      .get(`${global.adminurl}HireExpert/getOne/HireExpertNDeals/${Id}`)
      .then(responseData => {
        callback(global.getMessage("logList", 200, responseData));
      })
      .catch(err => {
        callback(global.getMessage("logList", 500, err));
      });
  },
  // update HireExperts n Deals
  updateHireExpertNDeals(dataToUpdate, Id, callback) {
    let obj = {};
    obj = {
      method: "put",
      url: `${global.adminurl}HireExpert/updateHireExpertAdmin/${Id}`,
      data: dataToUpdate
    };
    axios(obj)
      .then(responseData => {
        callback(responseData);
      })
      .catch(err => {
        callback(err);
      });
  },
  // get Invoice Data for Admin
  getInvoiceListForAdmin(data, callback) {
    let obj = {};
    obj = {
      method: "post",
      headers: {},
      url: `${global.adminurl}Transaction/getInvoiceDataForAdmin`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // download Invoice PDF for Admin
  downloadInvoicePdf(data, callback) {
    let obj = {};
    obj = {
      method: "post",
      headers: {},
      url: `${global.adminurl}Transaction/downloadInvoicePdf`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // getAllOffersNDealsForAdmin
  viewOffersNDealsList(data, callback) {
    let obj = {};
    obj = {
      method: "post",
      headers: {},
      url: `${global.adminurl}OffersNDeals/getAllOffersNDealsForAdmin`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get one OfferNDeal For Admin
  getOneOffer(Id, callback) {
    axios({
      method: "get",
      url: `${global.adminurl}OffersNDeals/${Id}`
    })
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  getFoodPackages(callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}Hotel/getFoodPackages/package`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  getInclusions(data, callback) {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Inclusions/getAll`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  cancelOffer: (data, callback) => {
    axios({
      method: "post",
      url: `${global.adminurl}OffersNDeals/cancelOffer`,
      data: data
    })
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  //update offer and deal admin
  updateOfferNDealForAdmin(data, Id, callback) {
    axios({
      method: "put",
      url: `${global.adminurl}OffersNDeals/updateOfferNDealForAdmin/${Id}`,
      data: data
    })
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  //getOneHotel for InfoAdmin

  getOneHotelForInfoAdmin(Id, callback) {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}Hotel/getOneHotelForInfoAdmin/${Id}`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  updateSimplyDestination: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}hotel/updateSimplyDestination`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  updateHotNTrend: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}hotel/updateHotNTrend`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // update hot deals in offer and deals
  updateHotDeals: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}OffersNDeals/updateHotDeals`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // crud api for Destination expert
  awsDestinationImageUpload: (data, callback) => {
    axios
      .post(`${global.imageUrl}api/upload/`, data, {
        headers: { "Content-type": "multipart/form-data" }
      })
      .then(response => {
        if (response.status === 200) {
          callback(response);
        }
      })
      .catch(e => {
        callback(e);
      });
  },
  saveDestination: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}DestinationExpert/`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  viewDestinationList: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}DestinationExpert/getAllDestinationExpert`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  deleteDestination(Id, callback) {
    let obj = {};
    obj = {
      method: "delete",
      url: `${global.adminurl}DestinationExpert/deleteDestinationExpertForAdmin/${Id}`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  getOneDestination: (data, callback) => {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}DestinationExpert/getOneDestinationExpertForAdmin/${data}`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  updateDestination(dataToUpdate, Id, callback) {
    let obj = {};
    obj = {
      method: "put",
      url: `${global.adminurl}DestinationExpert/updateDestinationExpertForAdmin/${Id}`,
      data: dataToUpdate
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // crud api for expert Talks
  saveExpert: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}ExpertTalks/`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  viewExpertList: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}ExpertTalks/getAllExpertTalks`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  deleteExpert(Id, callback) {
    let obj = {};
    obj = {
      method: "delete",
      url: `${global.adminurl}ExpertTalks/deleteExpertTalksForAdmin/${Id}`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  getOneExpert: (data, callback) => {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}ExpertTalks/getOneExpertTalksForAdmin/${data}`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  updateExpert(dataToUpdate, Id, callback) {
    let obj = {};
    obj = {
      method: "put",
      url: `${global.adminurl}ExpertTalks/updateExpertTalksForAdmin/${Id}`,
      data: dataToUpdate
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  awsExpertVideoUpload: (data, callback) => {
    axios
      .post(`${global.imageUrl}api/upload/`, data, {
        headers: { "Content-type": "multipart/form-data" }
      })
      .then(response => {
        if (response.status === 200) {
          callback(response);
        }
      })
      .catch(e => {
        callback(e);
      });
  },
  // update trending in hotel
  updateTrending: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}hotel/updateTrending`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  // view shortlist hotels
  viewShortlistHotels: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Favourite/getShortListHotelByUser`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // update subh offer deals in offer and deals
  updateSubhOffer: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}OffersNDeals/updateSubhOffer`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get All Hotel List for admin
  getAllHotelList: callback => {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}hotel/`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // save Lead Admin
  saveLeadAdmin: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Enquiries/saveLeadAdmin`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // view Leads For Admin
  viewLeadsForAdmin: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Enquiries/viewLeadsForAdmin`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get one lead
  getOneLead: (data, callback) => {
    let obj = {};
    obj = {
      method: "get",
      url: `${global.adminurl}Enquiries/getOneLeadForAdmin/lead/${data}`
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get Total Count For Admin Dashboard
  getTotalCountForAdmin: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Hotel/getTotalCountForAdminDashboard`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get Profile views Count For Admin Dashboard
  getProfileViewsCountForAdmin: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Hotel/getProfileViewsCountForAdmin`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get lead Count of hotel For Admin Dashboard
  getLeadCountForAdminDashboard: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Hotel/getLeadCountForAdminDashboard`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  generateExcelForUserLead: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Enquiries/generateExcelForUserLead`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get All Profile views Count For Admin Dashboard
  viewHotelsViewsList: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Hotel/getAllProfileCountForAdminDashboard`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get All Profile views Count For Admin Dashboard
  viewHotelsLeadList: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Hotel/getAllLeadCountForAdminDashboard`,
      data: data
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  // get meta data list
  getAllMetaData: (data, callback) => {
    let obj = {};
    obj = { method: "post", url: `${global.adminurl}MetaData/search`, data: data };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  getOneMetaData: (data, callback) => {
    let obj = {};
    obj = { method: "get", url: `${global.adminurl}MetaData/${data}` };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  updateMetaData(dataToUpdate, Id, callback) {
    let obj = {};
    obj = { method: "put", url: `${global.adminurl}MetaData/update/${Id}`, data: dataToUpdate };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  downloadExcelFile: (data, callback) => {
    let obj = {};
    obj = { method: "post", url: `${global.adminurl}MetaData/downloadExcelFile`, data: data };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  importExcelToDatabase: (data, callback) => {
    let obj = {};
    obj = { method: "post", url: `${global.adminurl}MetaData/importExcelToDatabase`, data: data };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  generateExcelForHotelViews: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Hotel/generateExcelForHotelViews`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },

  generateExcelForLeadViews: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Hotel/generateExcelForLeadViews`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  generateExcelForShortlist: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Favourite/generateExcelForShortlist`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  },
  generateExcelForOfferEnquiries: (data, callback) => {
    let obj = {};
    obj = {
      method: "post",
      url: `${global.adminurl}Enquiries/generateExcelForOfferEnquiries`,
      data: data,
      responseType: "blob"
    };
    axios(obj)
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback(e);
      });
  }
};
